<template>
	<div class="home-mobile" v-if="isMobile">
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>

		<div v-if="detailItem">
			<!-- 业务介绍 -->
			<div class="part1" :class="{'bottom-to-top-anim':showPart1Anim}">
				<div class="title-text" v-if="language==='0'">业务介绍</div>
				<div class="title-text" v-if="language==='1'">Business Introduction</div>
				<div class="title-line"></div>
				<div class="title1 margin-top20">
					{{detailItem.business.introduce}}
				</div>
				<div class="row-me row-center flex-wrap margin-top60">
					<div class="item-card row-me center-all posRelative" v-for="(item,index) in businessArray"
						:key="index" @mouseenter="mouseenter(index)" @click="clickBusiness(item)">
						<img :src="item.icon" class="img">
						<transition name="el-zoom-in-bottom">
							<div class="posAbsoluteAll item-card1 row-me center-all" @mouseleave="mouseleave()"
								v-show="businessIndex===index">
								<span class="margin-left10 margin-right10">{{item.business_name}}</span>
							</div>
						</transition>
					</div>
				</div>
			</div>

			<!-- 投资案例 -->
			<div class="part2 posRelative">
				<img src="../assets/img/bg_tzial.png" class="posAbsoluteAll">
				<div class="main-content" :class="{'bottom-to-top-anim':showPart2Anim}">
					<div class="title-text-white" v-if="language==='0'">投资案例</div>
					<div class="title-text-white" v-if="language==='1'">Portfolio</div>
					<div class="title-line"></div>
					<div class="title1 margin-top20" v-html="detailItem.portfolios.introduce"></div>

					<div class="margin-top60 carousel-div">
						<el-carousel ref="carousel" trigger="click" :autoplay="false" arrow="never" height="100%">
							<el-carousel-item v-for="(item,index) in caseArray" :key="item">
								<div class="column-me">
									<div>
										<img :src="item.portfolios_home_img" class="img-right">
									</div>
									<div class="left-div column-me column-center">
										<div class="title1">{{item.portfolios_name}}</div>
										<div class="title2 margin-top20" v-html="item.portfolios_introduce">
										</div>
										<div class="btn-green margin-top30" @click="clickCase(item)"
											v-if="language==='0'">了解更多</div>
										<div class="btn-green margin-top40" @click="clickCase(item)"
											v-if="language==='1'">Learn more</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>

					<div class="row-me center-all margin-top30">
						<img src="../assets/img/shang_d.png" class="img-next" v-if="caseIndex<=0">
						<img src="../assets/img/shang_n.png" class="img-next pointer"
							@click="clickChangeCase(caseIndex--)" v-else>
						<div class="row-me center-all margin-left70 margin-right70">
							<div class="item-point" :class="{'item-point-active':caseIndex===index}"
								v-for="(item,index) in caseArray" :key="index"></div>
						</div>
						<img src="../assets/img/xia_d.png" class="img-next" v-if="caseIndex>=caseArray.length-1">
						<img src="../assets/img/xia_n.png" class="img-next pointer"
							@click="clickChangeCase(caseIndex++)" v-else>
					</div>
				</div>
			</div>

			<!-- 新闻资讯 -->
			<div class="part3" :class="{'bottom-to-top-anim':showPart3Anim}">
				<div class="title-text" v-if="language==='0'">新闻资讯</div>
				<div class="title-text" v-if="language==='1'">News Information</div>
				<div class="title-line"></div>
				<div class="column-me margin-top20">
					<div class="item-view column-me" v-for="(item,index) in newsArray" :key="index"
						@click="clickNews(item)">
						<div v-if="index<4">
							<div class="title1">{{item.month_day}}<span
									class="title2 margin-left10">{{item.year}}</span>
							</div>
							<img :src="item.cover" class="img1 margin-top10">
							<div class="title3 line1 margin-top15">{{item.title}}</div>
							<div class="title4 line2 margin-top15">{{item.content}}</div>
							<div class="bottom-div row-me row-center flex-sb margin-top20">
								<div class="title5" v-if="language==='0'">了解更多</div>
								<div class="title5" v-if="language==='1'">Learn more</div>
								<img src="../assets/img/san_right.png" class="img-san">
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 关于我们 -->
			<div class="part4 posRelative">
				<div class="title-text" v-if="language==='0'">关于我们</div>
				<div class="title-text" v-if="language==='1'">About Us</div>
				<div class="title-line"></div>
				<img src="../assets/img/img_gywm.png" class="img2" :class="{'bottom-to-top-anim':showPart4Anim}"
					draggable="false">
				<div class="grey-div">
					<img src="../assets/img/gywm_logo.png" class="img1" draggable="false">
					<div class="title1 margin-top60" :class="{'bottom-to-top-anim':showPart4Anim}"
						v-html="detailItem.about.introduce">
					</div>
				</div>
			</div>

		</div>
	</div>


	<!-- 电脑端 -->
	<div class="home" v-else>
		<div class="banner posRelative">
			<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
			<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
			<video ref="bannerVideo" class="posAbsoluteAll video-div" :autoplay="true" loop="loop" :src="banner.url"
				:muted="isMuted" v-if="banner.type===2"></video>
			<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
				<span>{{banner.title}}</span>
				<span class="margin-top40">{{banner.sub_title}}</span>
			</div>
			<div v-if="banner.type===2">
				<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
					@click.stop="isMuted=false">
				<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else @click.stop="isMuted=true">
			</div>
		</div>

		<div v-if="detailItem">
			<!-- 业务介绍 -->
			<div ref="part1" class="part1" :class="{'bottom-to-top-anim':showPart1Anim}">
				<div class="title-text" v-if="language==='0'">业务介绍</div>
				<div class="title-text" v-if="language==='1'">Business Introduction</div>
				<div class="title-line"></div>
				<div class="title1 margin-top20">
					{{detailItem.business.introduce}}
				</div>
				<div class="row-me row-center flex-wrap margin-top60">
					<div class="item-card row-me center-all posRelative" v-for="(item,index) in businessArray"
						:key="index" @mouseenter="mouseenter(index)" @click="clickBusiness(item)">
						<img :src="item.icon" class="img">
						<transition name="el-zoom-in-bottom">
							<div class="posAbsoluteAll item-card1 row-me center-all" @mouseleave="mouseleave()"
								v-show="businessIndex===index">
								<span class="margin-left10 margin-right10">{{item.business_name}}</span>
							</div>
						</transition>
					</div>
				</div>
			</div>

			<!-- 投资案例 -->
			<div class="part2 posRelative">
				<img src="../assets/img/bg_tzial.png" class="posAbsoluteAll">
				<div ref="part2" class="main-content column-me column-center"
					:class="{'bottom-to-top-anim':showPart2Anim}">
					<div class="title-text-white" v-if="language==='0'">投资案例</div>
					<div class="title-text-white" v-if="language==='1'">Portfolio</div>
					<div class="title-line"></div>
					<div class="title1 margin-top20" v-html="detailItem.portfolios.introduce"></div>

					<div class="margin-top60 carousel-div">
						<el-carousel ref="carousel" trigger="click" :autoplay="false" arrow="never" height="100%"
							indicator-position="none">
							<el-carousel-item v-for="(item,index) in caseArray" :key="item">
								<div class="row-me">
									<div class="left-div column-me column-center">
										<div class="title1">{{item.portfolios_name}}</div>
										<div class="title2 margin-top40" v-html="item.portfolios_introduce">
										</div>
										<div class="btn-green margin-top40" @click="clickCase(item)"
											v-if="language==='0'">了解更多</div>
										<div class="btn-green margin-top40" @click="clickCase(item)"
											v-if="language==='1'">Learn more</div>
									</div>
									<div class="flex1">
										<img :src="item.portfolios_home_img" class="img-right">
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>

					<div class="row-me center-all margin-top30">
						<img src="../assets/img/shang_d.png" class="img-next" v-if="caseIndex<=0">
						<img src="../assets/img/shang_n.png" class="img-next pointer"
							@click="clickChangeCase(caseIndex--)" v-else>
						<div class="row-me center-all margin-left70 margin-right70">
							<div class="item-point" :class="{'item-point-active':caseIndex===index}"
								v-for="(item,index) in caseArray" :key="index"></div>
						</div>
						<img src="../assets/img/xia_d.png" class="img-next" v-if="caseIndex>=caseArray.length-1">
						<img src="../assets/img/xia_n.png" class="img-next pointer"
							@click="clickChangeCase(caseIndex++)" v-else>
					</div>
				</div>
			</div>

			<!-- 新闻资讯 -->
			<div ref="part3" class="part3" :class="{'bottom-to-top-anim':showPart3Anim}">
				<div class="title-text" v-if="language==='0'">新闻资讯</div>
				<div class="title-text" v-if="language==='1'">News Information</div>
				<div class="title-line"></div>
				<div class="row-me row-center margin-top50">
					<div class="item-view column-me" v-for="(item,index) in newsArray" :key="index"
						@click="clickNews(item)">
						<div v-if="index<4">
							<div class="title1">{{item.month_day}}<span
									class="title2 margin-left10">{{item.year}}</span>
							</div>
							<img :src="item.cover" class="img1 margin-top30">
							<div class="title3 line1 margin-top27">{{item.title}}</div>
							<div class="title4 line2 margin-top15">{{item.content}}</div>
							<div class="bottom-div row-me row-center flex-sb margin-top40">
								<div class="title5" v-if="language==='0'">了解更多</div>
								<div class="title5" v-if="language==='1'">Learn more</div>
								<img src="../assets/img/san_right.png" class="img-san">
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 关于我们 -->
			<div class="part4 posRelative">
				<div class="title-text" v-if="language==='0'">关于我们</div>
				<div class="title-text" v-if="language==='1'">About Us</div>
				<div class="title-line"></div>
				<img ref="part4" src="../assets/img/img_gywm.png" class="img2"
					:class="{'bottom-to-top-anim':showPart4Anim}" draggable="false">
				<div class="grey-div">
					<img src="../assets/img/gywm_logo.png" class="img1" draggable="false">
					<div class="title1 margin-top60" :class="{'bottom-to-top-anim':showPart4Anim}"
						v-html="detailItem.about.introduce">
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				businessIndex: -1,
				businessArray: [], //业务介绍
				caseArray: [], //投资案例
				caseIndex: 0,
				showBannerAnim: false,
				showPart1Anim: false,
				showPart2Anim: false,
				showPart3Anim: false,
				showPart4Anim: false,
				newsArray: [], //新闻资讯
				detailItem: null,
				isMuted: true, //视频是否静音
				language: '', //0中文，1英文
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			scrollTop: {
				handler: function(newVal, oldVal) {
					if (!this.$method.isElementNotInViewport(this.$refs['part1']) && !this.isMobile) {
						this.showPart1Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part2']) && !this.isMobile) {
						this.showPart2Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part3']) && !this.isMobile) {
						this.showPart3Anim = true
					}
					if (!this.$method.isElementNotInViewport(this.$refs['part4']) && !this.isMobile) {
						this.showPart4Anim = true
					}
				}
			},
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
				}
			}
		},
		computed: {
			scrollTop() {
				return this.$store.state.scrollTop;
			},
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.showPart1Anim = false
			this.showPart2Anim = false
			this.showPart3Anim = false
			this.showPart4Anim = false
		},
		mounted() {
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		methods: {
			//业务介绍
			clickBusiness(item) {
				this.$router.push({
					path: '/business'
				})
			},
			//投资案例
			clickCase(item) {
				this.$router.push({
					path: '/case'
				})
			},
			//新闻资讯
			clickNews(item) {
				this.$router.push({
					path: '/news',
					query: {
						id: item.id
					}
				})
			},
			mouseenter(index) {
				this.businessIndex = index
			},
			mouseleave() {
				this.businessIndex = -1
			},
			clickChangeCase(index) {
				this.$refs.carousel.setActiveItem(this.caseIndex)
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/home') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/home', {}).then((res) => {
					if (res) {
						this.detailItem = res.data
						this.businessArray = res.data.business.data
						this.caseArray = res.data.portfolios.data
						this.newsArray = res.data.news
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.home-mobile {
		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 22px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.part1 {
			background: #F5F5F7;
			padding: 20px;

			.title1 {
				font-size: 18px;
				color: #000000;
				line-height: 30px;
				letter-spacing: 7px;
			}

			.item-card {
				width: 47%;
				height: 100px;
				background: #FFFFFF;
				cursor: pointer;
				margin-bottom: 20px;
				margin-right: 20px;

				&:nth-child(2n) {
					margin-right: 0;
				}

				.img {
					width: 60px;
					height: 60px;
				}

				.item-card1 {
					background: #0B0F0F;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}

		.part2 {

			.main-content {
				position: relative;
				padding: 20px;

				.el-carousel {
					height: 100%;
				}

				.title1 {
					font-size: 18px;
					color: #FFFFFF;
					line-height: 30px;
					letter-spacing: 7px;
				}

				.carousel-div {
					height: 750px;
				}

				.left-div {
					background-color: white;
					padding: 20px;

					.title1 {
						font-size: 28px;
						color: #333333;
					}

					.title2 {
						height: 230px;
						overflow: auto;
						font-size: 18px;
						color: #333333;
						line-height: 25px;
						letter-spacing: 2px;
					}

					.btn-green {
						width: 150px;
						line-height: 40px;
						text-align: center;
						background: linear-gradient(59deg, #1D9E9B 0%, #63AE3C 100%);
						opacity: 1;
						border-radius: 0px;
						font-size: 15px;
						color: white;
						-webkit-user-select: none;
						cursor: pointer;
					}
				}

				.img-right {
					width: 100%;
					height: 290px;
					object-fit: cover;
				}

				.img-next {
					width: 40px;
					height: 40px;
				}

				.item-point {
					width: 12px;
					height: 12px;
					background: #1D9E9B;
					border-radius: 50%;
					opacity: 0.2;
					margin: 0 5px;

					&-active {
						opacity: 1;
					}
				}
			}
		}

		.part3 {
			padding: 20px;

			.item-view {
				width: 100%;
				cursor: pointer;
				margin-bottom: 30px;

				&:hover {
					.bottom-div {
						width: 100%;
					}
				}

				.title1 {
					font-size: 30px;
					color: #333333;
				}

				.title2 {
					font-size: 18px;
					color: #999999;
				}

				.img1 {
					width: 100%;
					height: 200px;
					object-fit: cover;
				}

				.title3 {
					font-size: 18px;
					color: #000000;
				}

				.title4 {
					font-size: 12px;
					color: #999999;
					line-height: 25px;
				}

				.bottom-div {
					width: 100%;
					border-bottom: 2px solid #1D9E9B;
					padding-bottom: 18px;
				}

				.title5 {
					font-size: 16px;
					color: #1D9E9B;
				}

				.img-san {
					width: 16px;
					height: 16px;
					object-fit: scale-down;
				}
			}
		}

		.part4 {
			padding: 20px;

			.grey-div {
				padding: 20px;
				background-color: #F5F5F7;
				margin-top: 76px;

				.title1 {
					font-size: 16px;
					color: #000000;
					line-height: 30px;
				}
			}

			.img1 {
				object-fit: scale-down;
			}

			.img2 {
				width: 220px;
				object-fit: scale-down;
				position: absolute;
				z-index: 10;
				right: 0;
				top: 15%;
			}
		}

	}

	.home {
		.banner {
			width: 100%;
			height: 100vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.part1 {
			background: #F5F5F7;
			padding: 100px 278px;

			.title1 {
				font-size: 18px;
				color: #000000;
				line-height: 30px;
				letter-spacing: 7px;
			}

			.item-card {
				width: 398px;
				height: 192px;
				background: #FFFFFF;
				cursor: pointer;
				margin-bottom: 50px;
				margin-right: 85px;

				&:nth-child(3n) {
					margin-right: 0;
				}

				.img {
					width: 120px;
					height: 120px;
				}

				.item-card1 {
					background: #0B0F0F;
					font-size: 26px;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}

		.part2 {
			height: 1000px;

			.main-content {
				position: absolute;
				height: 100%;
				padding: 0 278px;

				.el-carousel {
					height: 100%;
				}

				.title1 {
					font-size: 18px;
					color: #FFFFFF;
					line-height: 30px;
					letter-spacing: 7px;
				}

				.carousel-div {
					height: 550px;
				}

				.left-div {
					width: 45%;
					background-color: white;
					padding: 0 50px;

					.title1 {
						font-size: 40px;
						color: #333333;
					}

					.title2 {
						height: 250px;
						overflow: auto;
						font-size: 18px;
						color: #333333;
						line-height: 32px;
						letter-spacing: 2px;
					}

					.btn-green {
						width: 180px;
						line-height: 50px;
						text-align: center;
						background: linear-gradient(59deg, #1D9E9B 0%, #63AE3C 100%);
						opacity: 1;
						border-radius: 0px;
						font-size: 16px;
						color: white;
						-webkit-user-select: none;
						cursor: pointer;
					}
				}

				.img-right {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.img-next {
					width: 40px;
					height: 40px;
				}

				.item-point {
					width: 12px;
					height: 12px;
					background: #1D9E9B;
					border-radius: 50%;
					opacity: 0.2;
					margin: 0 5px;

					&-active {
						opacity: 1;
					}
				}
			}
		}

		.part3 {
			padding: 100px 278px;

			.item-view {
				width: 288px;
				cursor: pointer;
				margin-right: 71px;

				&:nth-child(4n) {
					margin-right: 0;
				}

				&:hover {
					.bottom-div {
						width: 100%;
						animation: bottomAnim 0.5s 1 ease-out;
					}

					@keyframes bottomAnim {
						0% {
							width: 50%;
						}

						100% {
							width: 100%;
						}
					}
				}

				.title1 {
					font-size: 30px;
					color: #333333;
				}

				.title2 {
					font-size: 18px;
					color: #999999;
				}

				.img1 {
					width: 288px;
					height: 172px;
					object-fit: cover;
				}

				.title3 {
					font-size: 22px;
					color: #000000;
				}

				.title4 {
					font-size: 16px;
					color: #999999;
					line-height: 25px;
				}

				.bottom-div {
					width: 50%;
					border-bottom: 2px solid #1D9E9B;
					padding-bottom: 18px;
				}

				.title5 {
					font-size: 16px;
					color: #1D9E9B;
				}

				.img-san {
					width: 16px;
					height: 16px;
					object-fit: scale-down;
				}
			}
		}

		.part4 {
			padding: 100px 278px;

			.grey-div {
				padding: 80px 138px 70px 139px;
				background-color: #F5F5F7;
				margin-top: 76px;

				.title1 {
					font-size: 18px;
					color: #000000;
					line-height: 35px;
				}
			}

			.img1 {
				width: 380px;
				height: 106px;
				object-fit: scale-down;
			}

			.img2 {
				width: 682px;
				height: 310px;
				object-fit: scale-down;
				position: absolute;
				z-index: 10;
				right: 278px;
				top: 100px;
			}
		}

	}
</style>
